<template>
  <div>
    <div class="mt-5">
      <block-text
        :text="passedData.error ? passedData.error : $t('dialog.defaultError')"
        color="secondary"
        type="body-2"
      />
    </div>
    <div class="mt-8 d-flex justify-end">
      <v-btn
        @click="closeDialog"
        width="240"
        outlined
        rounded
        :elevation="0"
        text
        :color="'primary'"
        :ripple="false"
        :plain="false"
        class="text-capitalize body-1 px-10 font-weight-bold text-center"
        >{{ $t("dialog.gotIt") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    passedData: {
      default: null,
    },
  },
  data() {
    return {
      iconComponent: null,
    };
  },
  async mounted() {
    this.componentLoader();
  },
  methods: {
    closeDialog() {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: false,
      });
    },
    async rejectTTU(transaction) {
      await this.$store.dispatch("wallet/rejectTTU", {
        walletID: transaction.walletId,
        transferID: transaction.userTransferID,
      });
      this.$eventHub.$emit("toggleDialog", {
        isOpen: false,
      });
    },
    async acceptTTU(transaction) {
      await this.$store.dispatch("wallet/acceptTTU", {
        walletID: transaction.walletId,
        transferID: transaction.userTransferID,
      });
      this.$eventHub.$emit("toggleDialog", {
        isOpen: false,
      });
    },
    async componentLoader() {
      if (this.passedData.dialogIcon) {
        let res = await (() => import("phosphor-vue"))``;
        this.iconComponent = res[this.passedData.dialogIcon];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-wrapper {
  position: relative;
  .v-avatar {
    min-width: 0;
    position: absolute;
    bottom: 0;
    right: -5px;
    ::v-deep svg {
      width: 18px;
      height: 18px;
      line,
      polyline {
        stroke-width: 25;
      }
    }
  }
}
</style>
